<template>
  <div class="page">
    <ECAside/>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import ECAside from '@/components/ECAside.vue'
import { useRouter } from 'vue-router'

const router = useRouter()

onMounted(()=>{
  if(window.innerWidth > 740){
    router.push('/')
    return
  }
})
</script>

<style scoped lang="scss">
.page{
  @media (min-width: 740px){
    display: none;
  }
}
</style>
